
import Vue from 'vue'
import store from '@/store'
import router from '@/router'
import { mapState, mapGetters, mapActions } from 'vuex'

import moment from 'moment'

export default Vue.extend({
  name: 'Guest',
  data () {
    return {
      selectedLock: [],
      loading: false
    }
  },
  computed: {
    headersLocks() {
      return [
        { text: this.$t('guest.table_col_device_icon'), value: 'icon', sortable: false },
        { text: this.$t('guest.table_col_device_name'), value: 'deviceName' },
      ]
    },
    headersBookings() {
      return [
        { text: this.$t('guest.table_col_effective'), value: 'effectiveTime' },
        { text: this.$t('guest.table_col_invalid'), value: 'invalidTime' },
        { text: this.$t('guest.table_col_booking_password'), value: 'password', sortable: false },
      ]
    },
    ...mapState({
      devices: (state: any) => state.devices.devices,
      bookings: (state: any) => state.passwords.bookings,
      locale: (state: any) => state.locale
    }),
  },
  watch: {
    selectedLock: async function(val) {
      if (val.length > 0) {
        console.log(`selectedLock: ${val[0].deviceId}`)
        let error = await this.loadBookings({deviceId: val[0].deviceId}).catch(err => {
          return err
        })

        if (error) {
          window.alert(error)
          console.log(`loadBookings error ${error.message}`)
          this.$router.push({name: 'Logout'})
        }

      }
    }
  },
  methods: {
    localMoment: function (datetimeValue) {
      if (this.locale == 'jp') {
        return moment(datetimeValue).locale('ja').format('LLL')  
      } else {
        return moment(datetimeValue).locale('en-gb').format('LLL')  
      }      
    },
    ...mapActions({
      loadBookings: 'passwords/loadBookings'
    })
  },
  async beforeRouteEnter (to, from, next) {

    console.log(to.query)
    
    let error = await store.dispatch('users/getUserDevices').catch(err => {
      return err
    })

    if (error) {
      window.alert(error)
      router.push({name: 'Logout'})

    } else {
      next()
    }
  },
})
